import React, { Component } from "react";
import {
  CRow,
  CCol,
  CCard,
  CCardHeader,
  CCardBody,
  CButton,
  CSpinner,
  CDropdown,
  CFormSelect,
  CFormInput,
  CInputGroup,
  CBreadcrumb,
  CModal,
  CModalBody,
  CModalContent,
  CModalHeader,
  CModalFooter,
  CModalTitle,
  CBreadcrumbItem,
  CForm,
  CFormLabel,
} from "@coreui/react";
import InputLabel from "@mui/material/InputLabel";
import NativeSelect from "@mui/material/NativeSelect";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { Button, Drawer } from "antd";
import ApiGateWay from "../../../constants/ApiCall";
import { filterQuery, returnFirstDegreeObjValue } from "src/constants/Utils";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TextField from "@mui/material/TextField";
class Miscellaneous extends Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [],
      show: false,
      openModal: false,
      openOk: false,
      total: 0,
      name: "",
      id: "",
      level: "",
      commission: "",
      filterOption: "",
      inputSearch: "",
      statusSearch: "",
      color: "",
      isLoading: true,
      mountedStyle: {},
    };
  }

  toast = (type, msg) => {
    toast[type](msg);
  };
  onClose = () => {
    this.setState({ openModal: false });
  };
  handleCloseModal = () => {
    this.setState({
      openModal: false,
      name: "",
      id: "",
      commission: "",
    });
  };

  handleAddModal = () => {
    this.setState({ openModal: true, name: "", amenity_id: "" });
  };

  handleModal = ({ name, default_id, commission, type }) => {
    this.setState({
      openModal: true,
      name: name,
      type: type,
      id: default_id,
      commission: commission,
    });
  };
  componentDidMount() {
    this.getCommission();
  }

  handleResponse = (res) => {
    this.toast("success", res.message);
    setTimeout(
      () => this.setState({ openModal: false }, () => this.getCommission()),
      200
    );
  };

  addEditModal = () => {
    var self = this;
    let { id, commission, level } = self.state;
    let dataToSend = {
      commission: commission,
      level: level,
    };

    var self = this;

    if (commission === "" || level === "") {
      self.toast("error", "Please enter valid inputs");
    } else {
      if (level === "percentage" && commission > 100) {
        self.toast("error", "Commission percentage should below 100");
      } else {
        ApiGateWay.patch(
          `admin/miscellaneous/${id}`,
          dataToSend,
          (response) => {
            if (response.success) {
              this.handleResponse(response);
            } else self.toast("error", response.message);
          }
        );
      }
    }
  };

  getCommission = () => {
    var self = this;
    let { filterOption, inputSearch, statusSearch } = self.state;
    let queryParams = "";
    if (filterOption !== "" && inputSearch !== "")
      queryParams += filterQuery(filterOption, inputSearch);
    if (statusSearch !== "") {
      queryParams += filterQuery("status", statusSearch);
    }
    ApiGateWay.get(`admin/miscellaneous`, (response) => {
      if (response.success) {
        self.setState({
          items: response.data.commissions,
          isLoading: false,
        });
      }
    });
  };
  onChangeHandler = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  render() {
    let {
      items,
      page,
      openModal,
      id,
      name,
      color,
      level,
      openOk,
      commission,
      isLoading,
    } = this.state;
    return (
      <>
        {isLoading ? (
          <div className="c-spinning">
            <CSpinner color="primary" variant="grow" />
          </div>
        ) : (
          <CRow>
            <div className="paginate-flex">
              <CBreadcrumb>
                <CBreadcrumbItem href="/#/dashboard">Home</CBreadcrumbItem>
                <CBreadcrumbItem active>Miscellaneous</CBreadcrumbItem>
              </CBreadcrumb>
            </div>

            <ToastContainer
              position="top-right"
              autoClose={3000}
              hideProgressBar={true}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnVisibilityChange
              draggable
              pauseOnHover
            />
            <CCol xl={12}>
              <Drawer
                placement="right"
                visible={openModal}
                onClose={() => this.handleCloseModal()}
                style={{ zIndex: "8987" }}
                keyboard={true}
                autoFocus={true}
                headerStyle={{ backgroundColor: "#ececec" }}
                title=" "
                width="30%"
              >
                <div
                  style={{
                    marginTop: "0.1rem",
                    padding: "2px",
                    paddingTop: "1rem",
                  }}
                >
                  <div className="mb-3">
                    <TextField
                      id="standard-basic"
                      label="Name"
                      type="text"
                      style={{ width: "50%", fontWeight: "300", color: "grey" }}
                      name="name"
                      value={name}
                      variant="standard"
                    />
                  </div>
                  <div className="mb-3 nmt-3">
                    <CFormSelect
                      className="cborderBottom nobor-rad"
                      name="level"
                      value={level}
                      onChange={this.onChangeHandler}
                      style={{
                        fontWeight: "400",
                        borderRadius: "inherit",
                      }}
                    >
                      <option value="percentage">Percentage</option>
                      <option value="fixed_amount">Fixed Amount</option>
                    </CFormSelect>
                  </div>
                  <div className="mb-3 nmt-3" style={{ position: "relative" }}>
                    <TextField
                      id="standard-basic"
                      label="Commission"
                      type="number"
                      style={{ width: "50%", fontWeight: "300", color: "grey" }}
                      InputProps={{ inputProps: { min: 0 } }}
                      onChange={this.onChangeHandler}
                      name="commission"
                      value={commission}
                      variant="standard"
                    />
                  </div>

                  <div
                    className="buttons_div buttons_flex"
                    style={{ position: "absolute", top: "90%", left: "65%" }}
                  >
                    <CButton
                      color="secondary"
                      onClick={() => this.handleCloseModal()}
                    >
                      Cancel
                    </CButton>
                    <CButton
                      color="info"
                      onClick={() => this.addEditModal()}
                      style={{ color: "white", marginLeft: "1rem" }}
                    >
                      Update
                    </CButton>{" "}
                  </div>
                </div>
              </Drawer>
              <CCard className="mb-4">
                <CCardHeader>Miscellaneous</CCardHeader>
                <CCardBody>
                  {items.length !== 0 ? (
                    <div>
                      <table
                        className="table table-hover"
                        style={{ textAlign: "left" }}
                      >
                        <thead>
                          <tr>
                            <th scope="col">S.No</th>

                            <th scope="col">Name</th>
                            <th scope="col">Type</th>
                            <th scope="col">Value</th>
                            <th scope="col"></th>
                          </tr>
                        </thead>
                        <tbody>
                          {items.map((type, i) => (
                            <tr key={i}>
                              <td className="font-weight-bold">{i + 1}</td>

                              <td className="font-weight-bold">{type.name}</td>

                              <td className="font-weight-bold">
                                {type.level === "percentage"
                                  ? "Percentage"
                                  : "Fixed Amount"}
                              </td>
                              <td className="font-weight-bold">
                                {type.commission}
                              </td>
                              <td
                                className="font-weight-bold"
                                onClick={() => this.handleModal(type)}
                              >
                                {" "}
                                <i
                                  className="fa fa-edit mr-1"
                                  style={{
                                    fontSize: "15px",
                                    color: "#3498FB",
                                    cursor: "pointer",
                                  }}
                                ></i>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  ) : (
                    <div className="no-data">No data found</div>
                  )}
                </CCardBody>
              </CCard>
            </CCol>
          </CRow>
        )}
      </>
    );
  }
}
export default Miscellaneous;
