import React from "react";
import ManagerDetail from "./views/pages/Hosts/Managers/ManagerDetail";
import Managers from "./views/pages/Hosts/Managers/ManagersList";
import Miscellaneous from "./views/pages/Miscellaneous/ Miscellaneous";
import Payout from "./views/pages/Payouts/Payout";

const Dashboard = React.lazy(() => import("./views/dashboard/Dashboard"));
const Amenities = React.lazy(() => import("./views/pages/Amenities/Amenities"));
const States = React.lazy(() => import("./views/pages/States/States"));
const Cities = React.lazy(() => import("./views/pages/States/Cities/Cities"));
const Customers = React.lazy(() => import("./views/pages/Customers/Customers"));
const CustomerEdit = React.lazy(() =>
  import("./views/pages/Customers/CustomersEdit")
);
const Requests = React.lazy(()=>import("./views/pages/Requests/Requests"))
const Contractors = React.lazy(()=>import("./views/pages/Contractors/Contractors"))
const Hosts = React.lazy(() => import("./views/pages/Hosts/Hosts"));
const HostEdit = React.lazy(() => import("./views/pages/Hosts/HostsEdit"));
const Bookings = React.lazy(() => import("./views/pages/Bookings/Bookings"));
const Rooms = React.lazy(() =>
  import("./views/pages/Properties/Rooms/RoomsList")
);
const RoomDetail = React.lazy(() =>
  import("./views/pages/Properties/Rooms/RoomDetail")
);
const Invoice = React.lazy(() => import("./views/pages/Invoice/Invoice"));
const Profile = React.lazy(() => import("./views/pages/profile/Profile"));
const Transaction = React.lazy(() =>
  import("./views/pages/Transaction/Transaction")
);
const Properties = React.lazy(() =>
  import("./views/pages/Properties/Properties")
);
const PropertyEdit = React.lazy(() =>
  import("./views/pages/Properties/EditProperty")
);

const routes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    key: "Dashboard",
    component: Dashboard,
  },
  {
    path: "/amenities",
    name: "Amenities",
    key: "Amenities",
    component: Amenities,
  },
  { path: "/states", name: "States", key: "States", component: States },
  {
    path: "/state/city/:state_id",
    name: "Cities",
    key: "Cities",
    component: Cities,
  },

  {
    path: "/properties",
    name: "Properties",
    component: Properties,
    key: "EditProp",
    breadName: "Properties",
  },
  {
    path: "/rooms/:property_id",
    name: "Rooms",
    key: "Rooms",
    component: Rooms,
  },
  {
    path: "/requests",
    name: "Requests",
    key: "Requests",
    component: Requests,
  },
  {
    path: "/room/:room_id",
    name: "RoomDetail",
    key: "Rooms",
    component: RoomDetail,
  },
  {
    path: "/property/:property_id",
    name: "Edit",
    component: PropertyEdit,
    key: "EditProp",
    breadName: "Edit",
  },
  {
    path: "/customers",
    name: "Customers",
    key: "Customers",
    component: Customers,
  },
  {
    path: "/customer/:customer_id",
    name: "Edit",
    key: "Customers",
    component: CustomerEdit,
  },
  {
    path: "/hosts",
    name: "Hosts",
    key: "Hosts",
    urlKey: "EditHosts",
    component: Hosts,
  },
  {
    path: "/contractors",
    name: "Contractors",
    key: "Contractors",
    component: Contractors,
  
  },
  {
    path: "/managers/:host_id",
    name: "Managers",
    key: "Managers",
    urlKey: "ManagersList",
    component: Managers,
  },
  {
    path: "/manager/detail/:manager_id",
    name: "ManagerDetail",
    key: "ManagerDetail",
    urlKey: "ManagersDetail",
    component: ManagerDetail,
  },
  {
    path: "/host/:host_id",
    name: "Hosts",

    key: "Hosts",
    component: HostEdit,
  },
  { path: "/bookings", name: "Bookings", key: "Bookings", component: Bookings },
  {
    path: "/transactions",
    name: "Transactions",
    key: "Transactions",
    component: Transaction,
  },
  {
    path: "/transactions/:from_date/:to_date",
    name: "Transactions",
    key: "Transactions",
    component: Transaction,
  },
  { path: "/invoice", name: "Invoices", key: "Invoices", component: Invoice },
  { path: "/payouts", name: "Payouts", key: "Payouts", component: Payout },
  { path: "/profile", name: "Profile", key: "Profile", component: Profile },
  { path: "/miscellaneous", name: "Miscellaneous", key: "Miscellaneous", component: Miscellaneous },
];

export default routes;
