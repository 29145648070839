import moment from "moment";
import cookie from "react-cookies";
import ApiCall from "./ApiCall";

export function returnFirstDegreeObjValue(obj, key) {
  return obj && (obj[key] || obj[key] === 0) ? obj[key] : "N/A";
}

export function returnThirdDegreeObjValue(masterobj, obj, key, postion) {
  if (
    masterobj &&
    masterobj[obj] &&
    masterobj[obj][key] &&
    (masterobj[obj][key][postion] || masterobj[obj][key][postion] === 0)
  )
    return masterobj[obj][key][postion];
  else return "N/A";
}
export function returnSecondDegreeObjValue(obj, key, postion) {
  if (obj && obj[key] && (obj[key][postion] || obj[key][postion] === 0))
    return obj[key][postion];
  else return "N/A";
}
export function returnSecondDegreeObjValued(obj, key, postion) {
  if (obj && obj[key] && (obj[key][postion] || obj[key][postion] === 0))
    return obj[key][postion];
  else return "-";
}
export const showMomentFormat = (date) => {
  return moment(date).format("DD-MM-YYYY");
};
export const showUSFormat = (date) => {
  return moment(date).format("MMM DD,YYYY");
};

export const filterQuery = (query, data) => {
  let filter = query;
  if (filter === query) {
    return `&${query}=${data}`;
  }
};
export function getIsoString(date) {
  return date !== "" && date !== isNaN ? moment(date).format("YYYY-MM-DD") : "";
}
export function getNormalIsoString(date) {
  return date !== "" && date !== isNaN ? moment(date).format() : "";
}
export function returnSimpleFormatedDateTime(dateString) {
  return dateString === ""
    ? ""
    : moment(dateString).format("DD MMM YYYY h:mm a");
}

export const uploadCloudinary = async (file, path) => {
  const signResponse = await fetch(ApiCall.signInUrl, {
    method: "POST",
    body: JSON.stringify({ path }),
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + cookie.load("token"),
    },
  });
  const signData = await signResponse.json();

  const url =
    ApiCall.cloudinary_base_url + signData.data.cloudname + "/auto/upload";
  const formData = new FormData();
  formData.append("file", file);
  formData.append("api_key", signData.data.apikey);
  formData.append("timestamp", signData.data.timestamp);
  formData.append("signature", signData.data.signature);
  formData.append("eager", "c_pad,h_300,w_400|c_crop,h_200,w_260");
  formData.append("folder", signData.data.folder);
  let response;
  await fetch(url, {
    method: "POST",
    body: formData,
  })
    .then((response) => {
      return response.text();
    })
    .then((data) => {
      return (response = JSON.parse(data));
    });
  return response;
};
export const isInvalidName = (value) => {
  let nameRegex = /^[a-zA-Z ]+$/;
  if (!nameRegex.test(value)) {
    return true;
  } else {
    return false;
  }
};
export const isInvalidEmail = (email) => {
  let eRegex =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  if (!eRegex.test(email)) return true;
  else return false;
};
export function getDateFormat(dateString) {
  return moment(dateString).format("DD MMM YYYY");
}
export function getUSFormat(number) {
  const input = number.replace(/\D/g, "").substring(0, 10); // First ten digits of input only
  const areaCode = input.substring(0, 3);
  const middle = input.substring(3, 6);
  const last = input.substring(6, 10);
  let newNumber = "";
  if (input.length > 6) {
    newNumber = `(${areaCode}) ${middle} - ${last}`;
  } else if (input.length > 3) {
    newNumber = `(${areaCode}) ${middle}`;
  } else if (input.length > 0) {
    newNumber = `(${areaCode}`;
  }
  return newNumber;
}
