import React, { Component } from "react";
import {
  CRow,
  CCol,
  CCard,
  CCardHeader,
  CCardBody,
  CButton,
  CSpinner,
  CDropdown,
  CFormSelect,
  CFormInput,
  CInputGroup,
  CInputGroupText,
  CBreadcrumb,
  CBreadcrumbItem,
} from "@coreui/react";
import ApiGateWay from "../../../../constants/ApiCall";
import {
  returnFirstDegreeObjValue,
  showMomentFormat,
  filterQuery,
  returnSecondDegreeObjValue,
} from "src/constants/Utils";
import "react-datepicker/dist/react-datepicker.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
let host_id;
class Managers extends Component {
  constructor(props) {
    super(props);
    host_id = this.props.match.params.host_id;
    this.state = {
      managers: [],
      page: 1,
      limit: 20,
      total: 0,
      isLoading: true,
      filterOption: "",
      inputSearch: "",
      statusSearch: "",
    };
  }
  toast = (type, msg) => {
    toast[type](msg);
  };

  componentDidMount() {
    this.getManagers();
  }

  getManagers = () => {
    var self = this;
    let { page, limit, filterOption, inputSearch, statusSearch, from, to } =
      self.state;
    let queryParams = "";
    if (filterOption !== "" && inputSearch !== "")
      queryParams += filterQuery(filterOption, inputSearch);
    ApiGateWay.get(
      `admin/hosts/manager/${host_id}?page=${page}&limit=${limit}${queryParams}`,
      (response) => {
        if (response.success) {
          let { managers } = response.data;
          self.setState({
            managers,

            isLoading: false,
          });
        }
      }
    );
  };

  onChangeHandler = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  pageChange = (page) => {
    var self = this;
    self.setState({ page: page }, () => {
      self.getManagers();
    });
  };
  redirectDetail = (id) => {
    var self = this;
    self.props.history.push(`/manager/detail/${id}`);
  };
  handleIncrementPage = () => {
    this.setState({ page: this.state.page + 1 }, () =>
      this.getManagers(this.state.page)
    );
  };
  handleDecrementPage = () => {
    this.setState({ page: this.state.page - 1 }, () =>
      this.getManagers(this.state.page)
    );
  };
  render() {
    let {
      managers,
      total,
      limit,
      page,
      filterOption,
      inputSearch,
      statusSearch,
      isLoading,
    } = this.state;

    return (
      <>
        {isLoading ? (
          <div className="c-spinning">
            <CSpinner color="primary" variant="grow" />
          </div>
        ) : (
          <CRow>
            <div className="paginate-flex">
              <CBreadcrumb>
                <CBreadcrumbItem href="/#/dashboard">Home</CBreadcrumbItem>
                <CBreadcrumbItem active>Managers</CBreadcrumbItem>
              </CBreadcrumb>
              <div className="paginate-flex-box">
                <div
                  className={
                    page !== 1 ? "paginate-box ma-r" : "paginate-disable ma-r"
                  }
                  onClick={() => this.handleDecrementPage()}
                >
                  <i
                    className={
                      page !== 1
                        ? "fas fa-angle-left"
                        : "fas fa-angle-left disabled"
                    }
                  ></i>
                </div>
                <div
                  className={
                    managers.length < 20 ? "paginate-disable" : "paginate-box"
                  }
                  onClick={() => this.handleIncrementPage()}
                >
                  <i
                    className={
                      managers.length < 20
                        ? "fas fa-angle-right disabled"
                        : "fas fa-angle-right"
                    }
                  ></i>
                </div>
              </div>
            </div>
            <ToastContainer
              position="top-right"
              autoClose={3000}
              hideProgressBar={true}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnVisibilityChange
              draggable
              pauseOnHover
            />
            <CCol xl={12}>
              <CCard className="mb-4">
                <CCardHeader>
                  Managers{" "}
                  <CInputGroup className="mb-3">
                    <CDropdown variant="input-group">
                      <CFormSelect
                        id="inputGroupSelect01"
                        className="form_select"
                        name="filterOption"
                        value={filterOption}
                        onChange={this.onChangeHandler}
                      >
                        <option value="">Select</option>
                        <option value="name">Name</option>
                        <option value="email">Email</option>
                        <option value="phone">Contact</option>
                      </CFormSelect>
                    </CDropdown>
                    <CFormInput
                      aria-label="Text input with dropdown button"
                      className="input-filter date-in"
                      value={inputSearch}
                      name="inputSearch"
                      onChange={this.onChangeHandler}
                    />
                    <CButton
                      color="primary "
                      onClick={() => this.getManagers()}
                      style={{ float: "right" }}
                      className="filter_button"
                    >
                      Search
                    </CButton>
                  </CInputGroup>
                </CCardHeader>

                <CCardBody>
                  {managers.length !== 0 ? (
                    <div>
                      <table
                        className="table table-hover"
                        style={{ textAlign: "left" }}
                      >
                        <thead>
                          <tr>
                            <th scope="col">S.No</th>
                            <th scope="col">Name </th>
                            <th scope="col">Email </th>
                            <th scope="col">Phone </th>
                            <th scope="col">Status</th>
                            <th scope="col">Date</th>
                            <th scope="col"></th>
                          </tr>
                        </thead>

                        <tbody>
                          {managers.map((manager, i) => (
                            <tr key={i}>
                              <td className="font-weight-bold">
                                {page * 20 - 20 + (i + 1)}
                              </td>

                              <td className="font-weight-bold ">
                                {returnFirstDegreeObjValue(manager, "name")}
                              </td>
                              <td className="font-weight-bold ">
                                {returnFirstDegreeObjValue(manager, "email")}{" "}
                              </td>
                              <td className="font-weight-bold">
                                {returnSecondDegreeObjValue(
                                  manager,
                                  "phone",
                                  "national_number"
                                )}
                              </td>
                              <td
                                className={
                                  manager.status === "active"
                                    ? "font-weight-bold text-capitalize green"
                                    : manager.status === "blocked"
                                    ? "font-weight-bold text-capitalize red"
                                    : manager.status === "pending"
                                    ? "font-weight-bold text-capitalize yellow"
                                    : "font-weight-bold text-capitalize"
                                }
                              >
                                {returnFirstDegreeObjValue(manager, "status")}
                              </td>
                              <td className="font-weight-bold">
                                {showMomentFormat(manager.updatedAt)}
                              </td>
                              <td className="font-weight-bold">
                                <i
                                  className="fa fa-edit mr-1"
                                  style={{
                                    fontSize: "16px",
                                    color: "#3498FB",
                                    cursor: "pointer",
                                  }}
                                  onClick={() =>
                                    this.redirectDetail(manager.manager_id)
                                  }
                                ></i>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  ) : (
                    <div className="no-data">No data found</div>
                  )}
                </CCardBody>
              </CCard>
            </CCol>
          </CRow>
        )}
      </>
    );
  }
}
export default Managers;
