import React, { Component } from 'react'
import condition from './Condition'
import { Redirect, Route } from 'react-router-dom'

class PrivateRouter extends Component {
  render() {
    var self = this
    var isLogin = condition()
    return (
      <Route
        render={(props) =>
          isLogin ? (
            <Route exact={self.props.exact} component={self.props.component} {...props} />
          ) : (
            <Redirect to="/" />
          )
        }
      />
    )
  }
}
export default PrivateRouter
