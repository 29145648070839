import React, { Component } from "react";
import {
  CRow,
  CCol,
  CCard,
  CCardHeader,
  CCardBody,
  CButton,
  CSpinner,
  CDropdown,
  CFormSelect,
  CFormInput,
  CInputGroup,
  CInputGroupText,
  CBreadcrumb,
  CBreadcrumbItem,
} from "@coreui/react";
import ApiGateWay from "../../../constants/ApiCall";
import {
  returnFirstDegreeObjValue,
  filterQuery,
  returnSecondDegreeObjValue,
  getIsoString,
  showUSFormat,
} from "src/constants/Utils";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
class Payout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      PayoutsList: [],
      page: 1,
      limit: 20,
      total: 0,
      isLoading: true,
      filterOption: "",
      inputSearch: "",
      statusSearch: this.props.location.search ? "created" : "",
      from: "",
      to: "",
    };
  }
  toast = (type, msg) => {
    toast[type](msg);
  };

  componentDidMount() {
    this.getPayouts();
  }

  getPayouts = () => {
    var self = this;
    let { page, limit, filterOption, inputSearch, statusSearch, from, to } =
      self.state;
    let queryParams = "";
    if (statusSearch !== "") {
      queryParams += filterQuery("status", statusSearch);
    }
    if (from !== "") queryParams += filterQuery("from", getIsoString(from));
    if (to !== "") queryParams += filterQuery("to", getIsoString(to));
    if (from > to) {
      self.toast("error", "Please provide valid From and To date");
    } else {
      ApiGateWay.get(
        `admin/payouts?page=${page}&limit=${limit}${queryParams}`,
        (response) => {
          if (response.success) {
            let { payouts } = response.data;
            self.setState({
              PayoutsList: payouts,
              isLoading: false,
            });
          }
        }
      );
    }
  };
  updateStateValues = (key, value) => {
    this.setState(
      {
        [key]: value,
      },
      function () {
        if (value === null) {
          this.setState({
            [key]: "",
          });
        }
      }
    );
  };
  handleOnToChange = (e) => {
    this.updateStateValues("to", e);
  };
  handleOnFromChange = (e) => {
    this.updateStateValues("from", e);
  };
  onChangeHandler = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  onChangeHandler = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  pageChange = (page) => {
    var self = this;
    self.setState({ page: page }, () => {
      self.getPayouts();
    });
  };
  handleIncrementPage = () => {
    this.setState({ page: this.state.page + 1 }, () =>
      this.getPayouts(this.state.page)
    );
  };
  handleDecrementPage = () => {
    this.setState({ page: this.state.page - 1 }, () =>
      this.getPayouts(this.state.page)
    );
  };
  render() {
    let {
      PayoutsList,
      total,
      limit,
      page,
      filterOption,
      inputSearch,
      statusSearch,
      isLoading,
    } = this.state;
    return (
      <>
        {isLoading ? (
          <div className="c-spinning">
            <CSpinner color="primary" variant="grow" />
          </div>
        ) : (
          <CRow>
            <div className="paginate-flex">
              <CBreadcrumb>
                <CBreadcrumbItem href="/#/dashboard">Home</CBreadcrumbItem>
                <CBreadcrumbItem active>Payouts</CBreadcrumbItem>
              </CBreadcrumb>
              <div className="paginate-flex-box">
                <div
                  className={
                    page !== 1 ? "paginate-box ma-r" : "paginate-disable ma-r"
                  }
                  onClick={() => this.handleDecrementPage()}
                >
                  <i
                    className={
                      page !== 1
                        ? "fas fa-angle-left"
                        : "fas fa-angle-left disabled"
                    }
                  ></i>
                </div>
                <div
                  className={
                    PayoutsList.length < 20
                      ? "paginate-disable"
                      : "paginate-box"
                  }
                  onClick={() => this.handleIncrementPage()}
                >
                  <i
                    className={
                      PayoutsList.length < 20
                        ? "fas fa-angle-right disabled"
                        : "fas fa-angle-right"
                    }
                  ></i>
                </div>
              </div>
            </div>
            <ToastContainer
              position="top-right"
              autoClose={3000}
              hideProgressBar={true}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnVisibilityChange
              draggable
              pauseOnHover
            />
            <CCol xl={12}>
              <CCard className="mb-4">
                <CCardHeader>
                  Payouts{" "}
                  <CInputGroup className="mb-3">
                    <DatePicker
                      selected={this.state.from}
                      maxDate={new Date()}
                      dateFormat="dd-MM-yyyy"
                      placeholderText="From"
                      className="filter-input-date"
                      onChange={this.handleOnFromChange}
                      name="from"
                      id="from"
                      style={{ fontWeight: "500" }}
                      showYearDropdown
                      showMonthDropdown
                      ariaDescribedBy="basic-addon2"
                    />
                    <CInputGroupText id="basic-addon2" className="group-date">
                      <label className="mb-remove" htmlFor="from">
                        <i className="fa fa-calendar"></i>
                      </label>
                    </CInputGroupText>
                    <DatePicker
                      selected={this.state.to}
                      maxDate={new Date()}
                      dateFormat="dd-MM-yyyy"
                      placeholderText="To"
                      className="filter-input-date"
                      onChange={this.handleOnToChange}
                      name="to"
                      id="to"
                      style={{ fontWeight: "500" }}
                      showYearDropdown
                      showMonthDropdown
                      ariaDescribedBy="basic-addon2"
                    />
                    <CInputGroupText id="basic-addon2" className="group-date">
                      <label className="mb-remove" htmlFor="to">
                        <i className="fa fa-calendar"></i>
                      </label>
                    </CInputGroupText>
                    <CFormSelect
                      className="form_drop status-in"
                      value={statusSearch}
                      name="statusSearch"
                      onChange={this.onChangeHandler}
                    >
                      <option value="">All</option>
                      <option value="pending">Pending</option>
                      <option value="paid">Paid</option>
                    </CFormSelect>

                    <CButton
                      color="primary "
                      onClick={() => this.getPayouts()}
                      style={{ float: "right" }}
                      className="filter_button"
                    >
                      Search
                    </CButton>
                  </CInputGroup>
                </CCardHeader>

                <CCardBody>
                  {PayoutsList.length !== 0 ? (
                    <div>
                      <table
                        className="table table-hover"
                        style={{ textAlign: "left" }}
                      >
                        <thead>
                          <tr>
                            <th scope="col">S.No</th>
                            <th scope="col">Settlement Date</th>
                            <th scope="col">No of Transactions </th>
                            <th scope="col">Amount </th>
                            <th scope="col">To </th>
                            <th scope="col">Transaction Date</th>
                            <th scope="col">Status</th>
                          </tr>
                        </thead>

                        <tbody>
                          {PayoutsList.map((transaction, i) => (
                            <tr key={i}>
                              <td className="font-weight-bold">
                                {page * 20 - 20 + (i + 1)}
                              </td>

                              <td className="font-weight-bold">
                                {showUSFormat(
                                  returnFirstDegreeObjValue(
                                    transaction,
                                    "createdAt"
                                  )
                                )}
                              </td>
                              <td className="font-weight-bold ">
                                {returnFirstDegreeObjValue(
                                  transaction,
                                  "count"
                                )}
                              </td>
                              <td className="font-weight-bold ">
                                {returnFirstDegreeObjValue(
                                  transaction,
                                  "amount"
                                )}{" "}
                              </td>
                              <td className="font-weight-bold text-capitalize">
                                {returnFirstDegreeObjValue(transaction, "to")}
                              </td>
                              <td className="font-weight-bold text-capitalize">
                                {showUSFormat(
                                  returnSecondDegreeObjValue(
                                    transaction,
                                    "date",
                                    "from"
                                  )
                                )}
                                {" - "}
                                {showUSFormat(
                                  returnSecondDegreeObjValue(
                                    transaction,
                                    "date",
                                    "to"
                                  )
                                )}
                              </td>
                              <td className="font-weight-bold text-capitalize">
                                {returnFirstDegreeObjValue(
                                  transaction,
                                  "status"
                                )}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  ) : (
                    <div className="no-data">No data found</div>
                  )}
                </CCardBody>
              </CCard>
            </CCol>
          </CRow>
        )}
      </>
    );
  }
}
export default Payout;
