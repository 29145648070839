import React, { Component } from "react";
import {
  CRow,
  CCol,
  CCard,
  CCardHeader,
  CCardBody,
  CFormLabel,
  CSpinner,
  CFormInput,
  CBreadcrumb,
  CBreadcrumbItem,
} from "@coreui/react";
import ApiGateWay from "../../../../constants/ApiCall";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
let manager_id;
class ManagerDetail extends Component {
  constructor(props) {
    super(props);
    manager_id = this.props.match.params.manager_id;
    this.state = {
      email: "",
      name: "",
      phone: "",
      status: "",
      managerDetail: {},
      is_approved: false,
      auto_approval: false,
      emailModal: false,
      isLoading: true,
    };
  }
  toast = (type, msg) => {
    toast[type](msg);
  };
  componentDidMount() {
    this.getHosts();
  }
  getHosts = () => {
    var self = this;
    ApiGateWay.get(`admin/hosts/manager/detail/${manager_id}`, (response) => {
      if (response.success) {
        let { manager } = response.data;
        self.setState({
          managerDetail: manager,
          isLoading: false,
        });
      }
    });
  };
  onChangeHandler = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleCheckChange = (e) => {
    this.setState({ [e.target.name]: e.target.checked });
  };

  render() {
    let { isLoading, managerDetail, status } = this.state;
    return isLoading ? (
      <div className="c-spinning">
        <CSpinner color="primary" variant="grow" />
      </div>
    ) : (
      <CRow>
        <CBreadcrumb>
          <CBreadcrumbItem href="/#/dashboard">Home</CBreadcrumbItem>
          <CBreadcrumbItem
            href=""
            onClick={() => this.props.history.goBack()}
            className="bread-manage"
          >
            Manager
          </CBreadcrumbItem>
          <CBreadcrumbItem href="">Detail</CBreadcrumbItem>
        </CBreadcrumb>
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={true}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnVisibilityChange
          draggable
          pauseOnHover
        />
        <CCol xl={12}>
          <CCard>
            <CCardHeader>
              Basic Details
              <div></div>
            </CCardHeader>
            <CCardBody className="card-div">
              <CRow>
                <div className="col-md-6">
                  <CCol xs="10" className="flex-content mt-1">
                    <CFormLabel htmlFor="exampleFormControlInput1">
                      Name
                    </CFormLabel>
                    <CFormInput
                      className="form-input-con"
                      type="text"
                      name="name"
                      value={managerDetail?.name}
                      onChange={this.onChangeHandler}
                      placeholder="Enter Name"
                    />
                  </CCol>
                  <CCol xs="10" className="flex-content mt-5">
                    <CFormLabel htmlFor="exampleFormControlInput1">
                      Phone
                    </CFormLabel>
                    <CFormInput
                      className="form-input-con"
                      type="text"
                      name="phone"
                      value={managerDetail?.phone?.national_number}
                      onChange={this.handleOnNumberChange}
                      placeholder="Enter Phone"
                      maxLength="15"
                    />
                  </CCol>
                </div>
                <div className="col-md-6">
                  <CCol xs="10" className="flex-content mt-1">
                    <CFormLabel htmlFor="exampleFormControlInput1">
                      Email
                    </CFormLabel>
                    <CFormInput
                      className="form-input-con"
                      type="text"
                      name="email"
                      value={managerDetail?.email}
                      onChange={this.onChangeHandler}
                      placeholder="Enter Email"
                      readOnly
                    />
                  </CCol>
                </div>
              </CRow>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    );
  }
}
export default ManagerDetail;
