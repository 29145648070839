import React from "react";
import { Route, Redirect } from "react-router-dom";
import condition from "./Condition";
import PropTypes from "prop-types";
const PublicRouter = ({ component: Component, restricted, ...rest }) => {
  var isLogin = condition();
  return (
    <Route
      {...rest}
      render={(props) =>
        isLogin ? (
          <Redirect to="/dashboard" exact />
        ) : (
          <Component {...props} exact />
        )
      }
    />
  );
};
PublicRouter.propTypes = {
  // component: PropTypes.func,
  restricted: PropTypes.func,
};
export default PublicRouter;
